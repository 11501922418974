<template>
  <div class="citas-pendientes-cliente">
    <v-data-table
      :headers="headers"
      :items="lista"
      :footer-props="{ itemsPerPageText: 'Citas' }"
      :search="search"
      :loading="loading_t"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="4" class="px-0">
            <v-text-field
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              label="Buscar"
              class="mt-6"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.folio="{ item }">
        <router-link
          :to="'/detallefactura/' + item.id"
          v-if="item.id != undefined"
        >
          {{ item.folio }}
        </router-link>
        <span v-else>
          {{ item.folio }}
        </span>
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "serviciosSinAgendar",
  props: ["inicio", "fin", "cliente_id"],
  data: () => ({
    loading_t: false,
    headers: [
      {
        align: "start",
        text: "Fecha",
        value: "fecha",
      },
      {
        text: "Factura",
        value: "folio",
      },
      {
        text: "Total",
        value: "total",
        sortable: false,
      },
    ],
    search: "",
    facturas: [],
  }),
  methods: {
    loadHistorial() {
      this.loading_t = true
      const body = {
        route: "/servicios_sin_agendar",
        params: {
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
          cliente_id: this.cliente_id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.facturas = response.data.data;
      }).finally(() => (this.loading_t = false))
    },
  },
  created() {
    this.loadHistorial();
  },
  computed: {
    lista() {
      return this.facturas.map((factura) => {
        factura.fecha = moment(factura.fecha).format("ll");
        factura.folio = this.$options.filters.folio(factura.folio);
        factura.total = parseFloat(factura.total)
        return factura;
      });
    },
  },
  watch: {
    inicio(val) {
      this.loadHistorial();
      return this.inicio;
    },
    fin(val) {
      this.loadHistorial();
      return this.fin;
    },
  },
};
</script>
