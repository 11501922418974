var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"detalle-cliente-historial mx-2"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-card',[_c('v-card-title',[_vm._v("Detalle cliente")]),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-col',{attrs:{"cols":"12","md":"6","sm":"8"}},[_c('v-card',{attrs:{"elevation":"0","outlined":""}},[_c('v-card-text',[(_vm.cliente)?_c('v-row',{attrs:{"align":"center","no-gutters":""}},[_c('v-col',{attrs:{"align":"left"}},[_c('v-avatar',{staticStyle:{"height":"80px","min-width":"80px","width":"80px"}},[_c('img',{attrs:{"src":_vm.$store.state.centro.route +
                                'clientes/' +
                                _vm.cliente.foto}})])],1),_c('v-col',[_c('h3',[_c('strong',[_vm._v(_vm._s(_vm.cliente.nombre))])]),_c('strong',[_vm._v("Cc / ID: ")]),_vm._v(" "+_vm._s(_vm.cliente.cedula)+" "),_c('br'),_c('strong',[_vm._v("Correo: ")]),_vm._v(" "+_vm._s(_vm.cliente.email)+" "),_c('br'),_c('strong',[_vm._v("Telefono: ")]),_vm._v(" "+_vm._s(_vm.cliente.telefono)+" "),_c('br'),_c('strong',[_vm._v("Puntos: ")]),_vm._v(" "+_vm._s(_vm.cliente.puntos)+" "),_c('br'),_c('strong',[_vm._v("Cumpleaños: ")]),_vm._v(" "+_vm._s(_vm.moment(_vm.cliente.fecha).format("ll"))+" ")])],1):_vm._e()],1)],1)],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha inicial","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_1),callback:function ($$v) {_vm.menu_1=$$v},expression:"menu_1"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_1 = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"3","sm":"6"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":20,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"outlined":"","rounded":"","dense":"","label":"Fecha final","append-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu_2),callback:function ($$v) {_vm.menu_2=$$v},expression:"menu_2"}},[_c('v-date-picker',{attrs:{"scrollable":"","locale":"es-co","color":"primary","first-day-of-week":"1"},on:{"input":function($event){_vm.menu_2 = false}},model:{value:(_vm.date_fin),callback:function ($$v) {_vm.date_fin=$$v},expression:"date_fin"}})],1)],1),_c('v-col',{attrs:{"cols":"12","md":"12","sm":"12"}},[_c('v-tabs',{attrs:{"color":_vm.color},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tooltip',{attrs:{"top":"","max-width":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                var on = ref.on;
                                var attrs = ref.attrs;
return [_c('v-tab',_vm._g(_vm._b({attrs:{"href":"#tab-1"}},'v-tab',attrs,false),on),[_c('span',{staticClass:"reduce-text"},[_vm._v(" Citas pendientes ")])])]}}])},[_c('span',[_vm._v("La citas confirmadas, y / o procesadas tambien se listaran en este apartado")])]),_c('v-tab',{attrs:{"href":"#tab-2"}},[_c('span',{staticClass:"reduce-text"},[_vm._v(" Citas pagadas ")])]),_c('v-tab',{attrs:{"href":"#tab-3"}},[_c('span',{staticClass:"reduce-text"},[_vm._v(" Citas canceladas ")])]),_c('v-tab',{attrs:{"href":"#tab-4"}},[_c('span',{staticClass:"reduce-text"},[_vm._v(" Servicios sin agendar ")])]),_c('v-tab',{attrs:{"href":"#tab-5"}},[_c('span',{staticClass:"reduce-text"},[_vm._v(" Productos adquiridos ")])]),_c('v-tabs-slider')],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',{attrs:{"value":"tab-1"}},[_c('citas-pendientes-cliente',{attrs:{"inicio":_vm.date,"fin":_vm.date_fin,"cliente_id":_vm.$route.params.cliente_id}})],1),_c('v-tab-item',{attrs:{"value":"tab-2"}},[_c('citas-pagadas-cliente',{attrs:{"inicio":_vm.date,"fin":_vm.date_fin,"cliente_id":_vm.$route.params.cliente_id}})],1),_c('v-tab-item',{attrs:{"value":"tab-3"}},[_c('citas-canceladas-cliente',{attrs:{"inicio":_vm.date,"fin":_vm.date_fin,"cliente_id":_vm.$route.params.cliente_id}})],1),_c('v-tab-item',{attrs:{"value":"tab-4"}},[_c('servicios-sin-agendar-cliente',{attrs:{"inicio":_vm.date,"fin":_vm.date_fin,"cliente_id":_vm.$route.params.cliente_id}})],1),_c('v-tab-item',{attrs:{"value":"tab-5"}},[_c('informe-producto-facturas-cliente',{attrs:{"inicio":_vm.date,"fin":_vm.date_fin,"cliente_id":_vm.$route.params.cliente_id}})],1)],1)],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }