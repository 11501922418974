<template>
  <div class="citas-pagadas-cliente">
    <v-data-table
      :headers="headers"
      :items="lista"
      :search="search"
      :footer-props="{ itemsPerPageText: 'Citas' }"
      :loading="loading_t"
      dense
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-col cols="4" class="px-0">
            <v-text-field
              outlined
              rounded
              dense
              append-icon="mdi-magnify"
              label="Buscar"
              class="mt-6"
              v-model="search"
            ></v-text-field>
          </v-col>
        </v-toolbar>
      </template>
      <template v-slot:item.total="{ item }">
        <router-link
          :to="'/detallefactura/' + item.factura_id"
          v-if="item.factura_id != undefined && item.view"
        >
          {{ item.total | currency }}
        </router-link>
        <span v-else>
          {{ item.total | currency }}
        </span>
      </template>
      <template v-slot:body.append>
        <tr>
          <th colspan="4">
            Total
          </th>
          <th colspan="4">
            {{ total_pagos | currency }}
          </th>
        </tr>
      </template>
      <template v-slot:item.cuadro="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              fab
              dark
              x-small
              v-on="on"
              color="purple darken-1"
              v-bind="attrs"
              v-if="item.cuadro > 0"
              @click="viewCuadro(item)"
            >
              <v-icon>mdi-folder-account</v-icon>
            </v-btn>
          </template>
          <span>Ver cuadro</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog
      v-model="openDialogCuadro"
      persistent
      :max-width="cotizacion ? '900' : '600'"
    >
      <v-card>
        <v-card-title class="headline">
          Cuadro clínico / Cotización
        </v-card-title>
        <v-card-text>
          <v-row dense v-if="!load_cuadro">
            <v-col cols="12" :md="cotizacion ? '6' : '10'" sm="6">
              <v-checkbox
                v-model="cotizacion"
                label="Realizar cotización"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row dense v-if="cotizacion && !load_cuadro" class="mt-2">
            <v-col cols="6" md="6">
              <v-text-field
                v-model="cuadro_values.cotizacion.motivo"
                label="Servicio a cotizar"
                type="text"
                outlined
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="12">
              <v-simple-table dense>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th></th>
                      <th>Serv / Prod</th>
                      <th>Detalle</th>
                      <th>Valor</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in cuadro_values.cotizacion.proceso"
                      :key="index"
                    >
                      <td>
                        <v-btn small icon @click="deleteItemCotizacion(index)">
                          <v-icon color="error">
                            mdi-delete
                          </v-icon>
                        </v-btn>
                      </td>
                      <td class="pt-4">
                        <v-text-field
                          v-model="item.item"
                          type="text"
                          outlined
                          dense
                        ></v-text-field>
                      </td>
                      <td class="pt-4">
                        <v-textarea
                          v-model="item.detalle"
                          rows="3"
                          outlined
                          dense
                          style="font-size: 14px;"
                        ></v-textarea>
                      </td>
                      <td class="pt-4">
                        <v-text-field
                          v-model.number="item.valor"
                          type="number"
                          outlined
                          dense
                        ></v-text-field>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="3"></td>
                      <td>
                        {{ totalizacionCotizacion | currency }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="4">
                        <v-btn text color="primary" @click="addItemCotizacion">
                          <v-icon left>mdi-plus</v-icon>
                          Añadir Serv/Prod
                        </v-btn>
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-col>
          </v-row>
          <v-row dense v-if="!cotizacion && !load_cuadro" class="mt-2">
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.motivo"
                rows="2"
                auto-grow
                label="Motivo de la cita"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.proceso"
                rows="4"
                auto-grow
                label="Proceso"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.resultado"
                rows="4"
                auto-grow
                label="Resultados"
                dense
              ></v-textarea>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <v-textarea
                v-model="cuadro_values.cuadro.recomendacion"
                rows="4"
                auto-grow
                label="Recomendaciones"
                dense
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense v-if="load_cuadro">
            <v-col cols="12" md="12">
              <div class="text-center">
                <v-progress-circular
                  :size="50"
                  color="primary"
                  indeterminate
                ></v-progress-circular>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-none d-sm-flex">
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="openDialogCuadro = false">
            cerrar
          </v-btn>
          <v-btn
            :dark="!loading"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('no_send')"
            >Guardar</v-btn
          >
          <v-btn
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('send')"
          >
            Guardar y Enviar
          </v-btn>
        </v-card-actions>
        <v-card-actions class="d-flex d-sm-none">
          <v-btn small color="error" text @click="openDialogCuadro = false">
            cerrar
          </v-btn>
          <v-btn
            x-small
            :dark="!loading"
            color="primary"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('no_send')"
            >Guardar</v-btn
          >
          <v-btn
            x-small
            :dark="!loading"
            :color="!loading ? 'black' : 'gray'"
            :loading="loading"
            :disabled="loading"
            @click="saveCuadro('send')"
          >
            Guardar y Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "citasPagadas",
  props: ["inicio", "fin", "cliente_id"],
  data: () => ({
    loading_t: false,
    headers: [
      {
        align: "start",
        text: "Fecha",
        value: "fecha",
        width: 155,
      },
      {
        text: "Hora",
        value: "hora",
        filterable: false,
        width: 95,
      },
      {
        text: "Profesional",
        value: "profesional",
        sortable: false,
      },
      {
        text: "Servicio",
        value: "servicio",
        sortable: false,
      },
      {
        text: "Precio",
        value: "total",
        filterable: false,
      },
      {
        text: "Agendó",
        value: "agendo",
        sortable: false,
      },
      {
        text: "Sede",
        value: "sede",
      },
      {
        text: "Notas",
        value: "notas",
        sortable: false,
        width: 250,
        filterable: false,
      },
      {
        text: "Clínico / Cotización",
        value: "cuadro",
        sortable: true,
        width: 80,
        filterable: false,
      },
    ],
    search: "",
    citas: [],

    loading: false,
    openDialogCuadro: false,
    load_cuadro: false,
    cotizacion: false,
    cuadro_values: {
      cotizacion: {
        motivo: null,
        proceso: null,
      },
      cuadro: {
        motivo: null,
        proceso: null,
        resultado: null,
        recomendacion: null,
      },
    },

    cita: null,
  }),
  methods: {
    loadHistorial() {
      this.loading_t = true;
      const body = {
        route: "/citas_pagadas_cliente",
        params: {
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
          cliente_id: this.cliente_id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) this.citas = response.data.data;
        })
        .finally(() => (this.loading_t = false));
    },
    viewCuadro(item) {
      this.openDialogCuadro = true;
      this.load_cuadro = true;

      const body = {
        route: "/cuadroClinicoCita",
        params: {
          cita_id: item.id,
        },
      };

      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            for (let cuadro of response.data.data) {
              if (cuadro.tipo == 1) {
                this.cuadro_values.cuadro = {
                  motivo: cuadro.motivo,
                  proceso: JSON.parse(cuadro.proceso).data,
                  resultado: cuadro.resultado,
                  recomendacion: cuadro.recomendacion,
                  id: cuadro.id,
                  cita_id: item.id,
                  cliente_id: this.cliente_id,
                };
              } else {
                this.cuadro_values.cotizacion = {
                  motivo: cuadro.motivo,
                  proceso: JSON.parse(cuadro.proceso).data,
                  id: cuadro.id,
                  cita_id: item.id,
                  cliente_id: this.cliente_id,
                };
              }
            }
          }
        })
        .finally(() => (this.load_cuadro = false));
    },

    addItemCotizacion() {
      if (this.cuadro_values.cotizacion.proceso == null)
        this.cuadro_values.cotizacion.proceso = [];
      this.cuadro_values.cotizacion.proceso.push({
        item: "",
        detalle: "",
        valor: 0,
      });
    },

    deleteItemCotizacion(index) {
      this.cuadro_values.cotizacion.proceso.splice(index, 1);
    },

    saveCuadro(type) {
      let values = null;

      let request = false;

      let alert = {
        alert: true,
        color: "error",
        text: "",
      };

      if (this.cotizacion) {
        values = this.cuadro_values.cotizacion;
      } else {
        values = this.cuadro_values.cuadro;
      }

      values = Object.assign(
        {
          tipo: this.cotizacion ? 2 : 1,
        },
        values
      );

      if (this.cotizacion) {
        if (values.motivo == null || values.motivo == "") {
          request = true;
          alert.text = "Debes ingresar un motivo";
        } else if (values.proceso == null) {
          request = true;
          alert.text = "Debes ingresar elementos a la cotización";
        }
      } else {
        if (values.motivo == null || values.motivo == "") {
          request = true;
          alert.text = "Debes ingresar un motivo";
        } else if (values.proceso == null) {
          request = true;
          alert.text = "Debes ingresar el proceso";
        } else if (values.resultado == null || values.resultado == "") {
          request = true;
          alert.text = "Debes ingresar un resultado";
        }
      }

      if (request) {
        this.$store.dispatch("setAlert", alert);
      } else {
        this.loading = true;
        values.proceso = {
          data: values.proceso,
        };
        values.proceso = JSON.stringify(values.proceso);

        const body = {
          route: "/update_cuadro",
          data: {
            data: values,
            id: values.id,
            type,
          },
        };

        this.$store
          .dispatch("axios_post", body)
          .then((response) => {
            if (response.data.success) {
              if (type == "no_send") {
                alert.text = `${response.data.data}`;
              } else {
                alert.text = `${response.data.data} \r\n ${response.data.email_status.data}`;
              }
              alert.color = "success";
              this.$store.dispatch("setAlert", alert);
              this.openDialogCuadro = false;
            }
          })
          .catch((error) => {
            alert.text = error.response.data.data;
            alert.color = "success";
            this.$store.dispatch("setAlert", alert);
          })
          .finally(() => (this.loading = false));
      }
    },
  },
  created() {
    this.loadHistorial();
  },
  computed: {
    lista() {
      return this.citas.map((cita) => {
        cita.fecha = moment(cita.fecha).format("ll");
        cita.hora = moment(cita.hora, "HH:mm:ss").format("h:mm A");
        cita.total = parseFloat(cita.total);
        cita.factura_id =
          cita.factura_id == "undefined" ? undefined : cita.factura_id;
        return cita;
      });
    },
    total_pagos() {
      return this.lista.reduce((index, item) => {
        return index + parseFloat(item.total);
      }, 0);
    },
    totalizacionCotizacion() {
      if (this.cuadro_values.cotizacion.proceso == null) return 0;
      else
        return this.cuadro_values.cotizacion.proceso.reduce((index, item) => {
          return index + item.valor;
        }, 0);
    },
  },
  watch: {
    inicio(val) {
      this.loadHistorial();
      return this.inicio;
    },
    fin(val) {
      this.loadHistorial();
      return this.fin;
    },
  },
};
</script>
