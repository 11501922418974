<template>
  <div class="detalle-cliente-historial mx-2">
    <v-row>
      <v-col cols="12" md="12" sm="12">
        <v-card>
          <v-card-title>Detalle cliente</v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-col cols="12" md="6" sm="8">
                  <v-card elevation="0" outlined>
                    <v-card-text>
                      <v-row align="center" no-gutters v-if="cliente">
                        <v-col align="left">
                          <v-avatar
                            style="height: 80px;min-width: 80px;width: 80px;"
                          >
                            <img
                              :src="
                                $store.state.centro.route +
                                  'clientes/' +
                                  cliente.foto
                              "
                            />
                          </v-avatar>
                        </v-col>
                        <v-col>
                          <h3>
                            <strong>{{ cliente.nombre }}</strong>
                          </h3>
                          <strong>Cc / ID: </strong> {{ cliente.cedula }}
                          <br />
                          <strong>Correo: </strong> {{ cliente.email }}
                          <br />
                          <strong>Telefono: </strong> {{ cliente.telefono }}
                          <br />
                          <strong>Puntos: </strong> {{ cliente.puntos }}
                          <br />
                          <strong>Cumpleaños: </strong>
                          {{ moment(cliente.fecha).format("ll") }}
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-menu
                  v-model="menu_1"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date"
                      outlined
                      rounded
                      dense
                      label="Fecha inicial"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_1 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="3" sm="6">
                <v-menu
                  v-model="menu_2"
                  :close-on-content-click="false"
                  :nudge-right="20"
                  transition="scale-transition"
                  offset-y
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="date_fin"
                      outlined
                      rounded
                      dense
                      label="Fecha final"
                      append-icon="mdi-calendar"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    scrollable
                    v-model="date_fin"
                    locale="es-co"
                    color="primary"
                    first-day-of-week="1"
                    @input="menu_2 = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" md="12" sm="12">
                <v-tabs v-model="tab" :color="color">
                  <v-tooltip top max-width="200">
                    <template v-slot:activator="{ on, attrs }">
                      <v-tab href="#tab-1" v-on="on" v-bind="attrs">
                        <span class="reduce-text">
                          Citas pendientes
                        </span>
                      </v-tab>
                    </template>
                    <span
                      >La citas confirmadas, y / o procesadas tambien se
                      listaran en este apartado</span
                    >
                  </v-tooltip>
                  <v-tab href="#tab-2">
                    <span class="reduce-text">
                      Citas pagadas
                    </span>
                  </v-tab>
                  <v-tab href="#tab-3">
                    <span class="reduce-text">
                      Citas canceladas
                    </span>
                  </v-tab>
                  <v-tab href="#tab-4">
                    <span class="reduce-text">
                      Servicios sin agendar
                    </span>
                  </v-tab>
                  <v-tab href="#tab-5">
                    <span class="reduce-text">
                      Productos adquiridos
                    </span>
                  </v-tab>
                  <!-- <v-tab href="#tab-6">
                    <span class="reduce-text">
                      Cuadro clínico / Cotización
                    </span>
                  </v-tab> -->
                  <v-tabs-slider></v-tabs-slider>
                </v-tabs>
                <v-tabs-items v-model="tab">
                  <v-tab-item value="tab-1">
                    <citas-pendientes-cliente
                      :inicio="date"
                      :fin="date_fin"
                      :cliente_id="$route.params.cliente_id"
                    />
                  </v-tab-item>
                  <v-tab-item value="tab-2">
                    <citas-pagadas-cliente
                      :inicio="date"
                      :fin="date_fin"
                      :cliente_id="$route.params.cliente_id"
                    />
                  </v-tab-item>
                  <v-tab-item value="tab-3">
                    <citas-canceladas-cliente
                      :inicio="date"
                      :fin="date_fin"
                      :cliente_id="$route.params.cliente_id"
                    />
                  </v-tab-item>
                  <v-tab-item value="tab-4">
                    <servicios-sin-agendar-cliente
                      :inicio="date"
                      :fin="date_fin"
                      :cliente_id="$route.params.cliente_id"
                    />
                  </v-tab-item>
                  <v-tab-item value="tab-5">
                    <informe-producto-facturas-cliente
                      :inicio="date"
                      :fin="date_fin"
                      :cliente_id="$route.params.cliente_id"
                    />
                  </v-tab-item>
                </v-tabs-items>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import * as moment from "moment";
import CitasPendientesCliente from "../../../components/CitasPendientesCliente.vue";
import CitasPagadasCliente from "../../../components/CitasPagadasCliente.vue";
import CitasCanceladasCliente from "../../../components/CitasCanceladasCliente.vue";
import InformeProductoFacturasCliente from "../../../components/InformeProductoFacturasCliente.vue";
import ServiciosSinAgendarCliente from "../../../components/ServiciosSinAgendarCliente.vue";
moment.locale("es");
export default {
  components: {
    CitasPendientesCliente,
    CitasPagadasCliente,
    CitasCanceladasCliente,
    InformeProductoFacturasCliente,
    ServiciosSinAgendarCliente,
  },
  data: () => ({
    cliente: null,
    date: moment().format("YYYY-MM-DD"),
    date_fin: moment().format("YYYY-MM-DD"),
    menu_1: false,
    menu_2: false,
    tab: "tab-1",
    color: "primary",
  }),
  methods: {
    loadDetalleCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.$route.params.cliente_id,
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) {
          this.cliente = response.data.data;
        }
      });
    },
    moment(input) {
      return moment(input);
    },
  },
  created() {
    this.loadDetalleCliente();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  watch: {
    tab(val) {
      if (val == "tab-1") this.color = "primary";
      if (val == "tab-2") this.color = "success";
      if (val == "tab-3") this.color = "error";
      if (val == "tab-4") this.color = "success";
      if (val == "tab-5") this.color = "teal";
      return this.tab;
    },
  },
};
</script>

<style scoped>
.v-tab{
  padding: 0 6px !important;
}
.reduce-text {
  font-size: 12px !important;
  font-weight: bold;
}
</style>
