<template>
  <div class="informe-producto-factura-cliente">
    <v-data-table
      :headers="headers"
      :footer-props="{ itemsPerPageText: 'Productos' }"
      :search="search"
      :items="lista"
      :loading="loading_t"
      dense
    >
      <template v-slot:item.folio="{ item }">
        <router-link :to="'/detallefactura/' + item.factura_id" v-if="item.view">
          {{ item.folio }}
        </router-link>
        <span v-else>
          {{ item.folio }}
        </span>
      </template>
      <template v-slot:item.total="{ item }">
        {{ item.total | currency }}
      </template>
      <template v-slot:body.append>
        <tr>
          <th colspan="4">
            Total
          </th>
          <th colspan="2">
            {{total_pagos | currency}}
          </th>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import * as moment from "moment";
moment.locale("es");
export default {
  name: "informeProductoFacturas",
  props: ["cliente_id", "inicio", "fin"],
  data: () => ({
    loading_t: false,
    search: "",
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
      },
      {
        text: "Producto",
        value: "producto",
      },
      {
        text: "Folio",
        value: "folio",
      },
      {
        text: "Cantidad",
        value: "cantidad",
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Sede",
        value: "sede",
      },
    ],
    productos: [],
  }),
  methods: {
    loadDetalle() {
      this.loading_t = true
      const body = {
        route: "/producto_movimientos_facturas_cliente",
        params: {
          id: this.cliente_id,
          date: {
            inicio: this.inicio,
            fin: this.fin,
          },
        },
      };
      this.$store.dispatch("get_data", body).then((response) => {
        if (response.data.success) this.productos = response.data.data;
      }).finally(() => (this.loading_t = false))
    },
  },
  created() {
    this.loadDetalle();
  },
  computed: {
    lista() {
      return this.productos.map((factura) => {
        factura.fecha = moment(factura.fecha).format("ll");
        factura.folio = this.$options.filters.folio(factura.folio);
        factura.total = parseFloat(factura.total);
        return factura;
      });
    },
    total_pagos(){
      return this.lista.reduce((index, item) => {
        return index + parseFloat(item.total)
      }, 0)
    }
  },
  watch: {
    inicio(val) {
      this.loadDetalle();
      return this.inicio;
    },
    fin(val) {
      this.loadDetalle();
      return this.fin;
    },
  },
};
</script>
